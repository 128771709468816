import Choices from "choices.js";

export default class Global {

    constructor() {
        this.initChoices();
    }

    initChoices() {
        const elements = document.querySelectorAll('.js-choice');
        let choices;

        elements.forEach(element => {
            choices = new Choices(element);
        });
    }
}
