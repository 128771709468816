export const fadeIn = (el, displayStyle = 'block', smooth = true) => {
    el.style.opacity = 0;
    el.style.setProperty("display", displayStyle, "important");
    if (smooth) {
        let opacity = 0;
        let request;

        const animation = () => {
            el.style.opacity = opacity += 0.04;
            if (opacity >= 1) {
                el.style.opacity = opacity = 1;
                cancelAnimationFrame(request);
            }
        };

        const rAf = () => {
            request = requestAnimationFrame(rAf);
            animation();
        };
        rAf();

    } else {
        el.style.opacity = 1;
    }
};

export const fadeOut = (el,smooth = true) => {
    if (smooth) {
        let opacity = el.style.opacity;
        let request;

        const animation = () => {
            el.style.opacity = opacity -= 0.04;
            if (opacity <= 0) {
                el.style.opacity = opacity = 0;
                el.style.setProperty("display", 'none', "important");
                cancelAnimationFrame(request);
            }
        };

        const rAf = () => {
            request = requestAnimationFrame(rAf);
            animation();
        };
        rAf();
    } else {
        el.style.setProperty("display", 'none', "important");
        el.style.opacity = 0;
    }
};
