import {fadeIn, fadeOut} from "../functions/functions";

export default class Subscribe {
    constructor() {
        if (document.getElementById('subscribe') !== null) {
            this.initForm();
        }
    }

    initForm() {
        const inputAlimentatie = document.querySelectorAll('input[name=alimentatie]');
        const alimentatieWrapper = document.getElementById('alimentatieWrapper');

        const inputPartner = document.querySelectorAll('input[name=partner]');
        const partnerWrapper = document.getElementById('partnerWrapper');

        const inputLoondienst = document.querySelectorAll('input[name=loondienst]');
        const loondienstWrapper = document.getElementById('loondienstWrapper');

        const inputUitkering = document.querySelectorAll('input[name=uitkering]');
        const uitkeringWrapper = document.getElementById('uitkeringWrapper');


        inputAlimentatie.forEach(radio => {
            radio.addEventListener('change', e => {
                if (radio.value === 'Ja') {
                    fadeIn(alimentatieWrapper);
                } else {
                    fadeOut(alimentatieWrapper);
                }
            });
        });

        inputPartner.forEach(radio => {
            radio.addEventListener('change', e => {
                if (radio.value === 'Ja') {
                    fadeIn(partnerWrapper);
                } else {
                    fadeOut(partnerWrapper);
                }
            });
        });

        inputLoondienst.forEach(radio => {
            radio.addEventListener('change', e => {
                if (radio.value === 'Ja') {
                    fadeIn(loondienstWrapper);
                } else {
                    fadeOut(loondienstWrapper);
                }
            });
        });

        inputUitkering.forEach(radio => {
            radio.addEventListener('change', e => {
                if (radio.value === 'Ja') {
                    fadeIn(uitkeringWrapper);
                } else {
                    fadeOut(uitkeringWrapper);
                }
            });
        });
    }

}
