import Swiper, {Autoplay} from 'swiper';
Swiper.use([Autoplay]);

export default class Home {
    constructor() {
        if (document.getElementById('home') !== null) {
            this.initDisciplines();
            this.initProperties();
            this.initSocial();
            this.initServices();
        }
    }

    initDisciplines() {
        new Swiper('#disciplines .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        });
    }

    initProperties() {
        new Swiper('#properties .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 2500,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        });
    }

    initSocial() {
        new Swiper('#social .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            // autoplay: {
            //     delay: 2500,
            // },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        });
    }

    initServices() {
        new Swiper('#services .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 2500,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        });
    }

}
