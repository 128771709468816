import Global from "./general/global";
import Nav from "./general/nav";
import Home from "./pages/home";
import Subscribe from "./pages/subscribe";
import Properties from "./pages/properties";
import Waardebepaling from "./pages/waardebepaling";
import Property from "./pages/property";
import Contact from "./pages/contact";

require('./bootstrap');

new Global();
new Nav();
new Home();
new Subscribe();
new Properties();
new Property();
new Contact();
new Waardebepaling();
