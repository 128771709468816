export default class Nav {

    constructor() {
        this.resizeWindow();
        this.mobileNav();
        this.fixOnScroll();
    }

    resizeWindow() {
        let resizeTimer;
        window.addEventListener('resize', e => {
            if (resizeTimer) clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.mobileNav();
                this.fixOnScroll();
            }, 100, e);
        });
    }

    fixOnScroll() {
        const handleScroll = function () {
            if (this.scrollY > 0) {
                document.body.classList.add('nav-fixed');
            } else {
                document.body.classList.remove('nav-fixed');
            }
        };
        if (window.innerWidth >= 992 && (document.body.offsetHeight - window.innerHeight) > 40) {
            window.addEventListener('scroll', handleScroll);
        } else {
            window.removeEventListener('scroll', handleScroll);
            document.body.classList.remove('nav-fixed');
        }
    }

    mobileNav() {
        if (window.innerWidth < 1200) {
            const burger = document.querySelector('header .burger');

            burger.removeEventListener('click', this.handleClick);
            burger.addEventListener('click', this.handleClick);

            document.querySelectorAll('header > nav > .container-fluid > ul > li > a + ul').forEach(function (el) {
                let link = el.previousElementSibling;

                link.addEventListener('click', e => {
                    if(link.classList.contains('open') === false){
                        e.preventDefault();
                    }

                    document.querySelectorAll('header > .container-fluid > nav > ul > li > a').forEach(function (a) {
                        a.classList.remove('open');
                    });

                    link.classList.add('open');
                });
            });

            if (document.querySelector('header > nav > .container-fluid a.active') !== null) {
                document.querySelector('header > nav > .container-fluid a.active').closest('.container-fluid > ul > li').classList.add('active');
            }

        } else {
            document.body.classList.remove('nav-open');

            document.querySelectorAll('header > .container-fluid > nav > ul li').forEach((li, i) => {
                let ul = li.querySelector('ul');
                if (ul !== null) {
                    li.querySelector('a').addEventListener('touchstart', e => {
                        var style = window.getComputedStyle(ul);
                        if (style.maxHeight === '0px') {
                            e.preventDefault();
                        }
                    });
                }
            });
        }
    }

    handleClick(e) {
        e.preventDefault();
        document.body.classList.toggle('nav-open');
    };
}
