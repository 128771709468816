import Swiper, {Autoplay, Navigation} from 'swiper';
Swiper.use([Autoplay, Navigation]);

export default class Waardebepaling {
    constructor() {
        this.reviewSlider();
    }

    reviewSlider() {
        if(document.querySelector('#reviewSlider')){
            new Swiper('#reviewSlider .swiper-container', {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '#reviewSlider .swiper-next',
                    prevEl: '#reviewSlider .swiper-prev',
                },
            });
        }
    }
}
