
export default class Properties {
    constructor() {
        if (document.getElementById('frmZoeken') !== null) {
            this.initSearch();
        }
    }

    initSearch() {
        const selectKoopHuur = document.querySelector('select[name=koophuur]');
        const koopprijs = document.querySelectorAll('.koopprijs');
        const huurprijs = document.querySelectorAll('.huurprijs');

        selectKoopHuur.addEventListener('change', e => {
            console.log(selectKoopHuur.value);
            if (selectKoopHuur.value === 'koop') {
                koopprijs.forEach(kp => {
                    huurprijs.forEach(hp => {
                        hp.classList.add('d-none');
                    });
                    kp.classList.remove('d-none');
                })
            } else {
                huurprijs.forEach(hp => {
                    koopprijs.forEach(kp => {
                        kp.classList.add('d-none');
                    });
                    hp.classList.remove('d-none');
                })
            }
        });
    }

}
