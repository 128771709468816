// import Glide from '@glidejs/glide';
import Luminous, { LuminousGallery } from 'luminous-lightbox';
import Swiper from 'swiper';

export default class Property {

    constructor() {
        this.detailSlider();
        this.readmore();
        this.luminous();
    }

    detailSlider() {
        if (document.querySelector('#property #property-slider')) {
      
            const swiper = new Swiper('#property #property-slider .swiper-container', {});

            // Om een of andere vage reden werken de buttons niet, dus maar even een omweg.
            document.querySelector('#property #property-slider .swiper-container .swiper-button-prev').addEventListener('click', (ev) => {
                swiper.slidePrev();
            });

            document.querySelector('#property #property-slider .swiper-container .swiper-button-next').addEventListener('click', (ev) => {
                swiper.slideNext();
            });
        }
    }

    readmore() {
        if (document.querySelector('#property') || document.querySelector('#project')) {
            const text = document.querySelector('#omschrijving .text');
            const height = text.offsetHeight;
            const readmore = document.querySelector('#omschrijving .read-more');

            // Set height of description
            text.style.height = '280px';

            readmore.addEventListener('click', function(ev) {
                ev.preventDefault();

                text.style.height = height + 'px';

                readmore.style.display = 'none';
            });
        }
    }

    luminous() {
        new LuminousGallery(document.querySelectorAll('#fotos .foto .luminous'));
        new LuminousGallery(document.querySelectorAll('#plattegronden .foto .luminous'));
    }
}